<template>
  <div class="contentView">
    <div class="contentView_head" v-if="!hideTitle">
      <div v-if="topicList.length" class="topicList">
        <a
          @click.stop="handleTagClick(item)"
          v-for="(item, index) in topicList"
          :key="index"
          class="topic"
        >#<span v-html="item"></span>#</a
        >
      </div>
      <div class="title-container">
        <p class="topTag" v-show="isTop">置顶</p>
        <p
          class="title"
          :class="{ deleteLineClamp: $route.name === 'topicDetail' }"
          style=""
          v-html="title"
        />
      </div>
    </div>
    <div class="contentView_contentText" :class="!isDetail && 'ellipsis'">
      <!-- todo 帖子中的话题-->
      <div>
        <span v-if="showVote" class="tagTitle">#{{ title }}#</span>
        <span
          v-for="(item, index) in articleLabels"
          :key="index"
          class="tagTitle"
          @click.stop="handleTagClick(item.labelName)"
        >
          #{{ item.labelName }}#
        </span>
      </div>
      <!-- todo  帖子内容-->
      <div
        class="content"
        ref="content"
        v-html="handleContent(content)"
        :class="{ content_indent: contentIndent, detailText: isDetail }"
      />
    </div>
    <div
      @click.stop=""
      class="vote-container"
      id="vote"
      v-if="JSON.stringify(this.articleVoteVO) != '{}'"
    >
      <div class="vote-head">
        <h3>{{ articleVoteVO.title }}</h3>
        <van-tag
          color="rgba(254,88,0,0.1)"
          text-color="#FE5800"
          style="margin-left: 10px"
          type="primary"
        >{{ articleVoteVO.type === 1 ? '单选' : '多选' }}
        </van-tag>
      </div>
      <div v-if="false" class="vote-info">
        <van-tag
          color="rgba(254,88,0,0.1)"
          text-color="#FE5800"
          v-if="articleVoteVO.maxOptionalCount === 1"
          type="primary"
        >单选
        </van-tag>
        <span v-if="articleVoteVO.maxOptionalCount > 1">{{
            `最多${articleVoteVO.maxOptionalCount}项`
          }}</span>
        <span class="long">|</span>
        <span style="margin-left: 8px"
        >{{ articleVoteVO.endTime.substring(0, 10) }}结束</span
        >
      </div>
      <div class="underline"></div>
      <ul class="vote-option">
        <li
          :class="{
            active: activeVoteList.includes(item.id),
            isAlreadyVote: item.isChoice && articleVoteVO.isAlreadyVote,
            noAlreadyVote: !item.isChoice && articleVoteVO.isAlreadyVote
          }"
          @click="addVote(item)"
          v-for="item in isVoteExpand
            ? articleVoteVO.voteOptions
            : [articleVoteVO.voteOptions[0], articleVoteVO.voteOptions[1]]"
          :key="item.id"
        >
          <p class="content">{{ item.content }}</p>
          <img
            v-if="item.isChoice && articleVoteVO.isAlreadyVote"
            src="~@/assets/imgs/article/Havetovote.png"
            alt=""
          />
          <p v-if="articleVoteVO.isAlreadyVote" class="percentage">
            {{
              Math.round(
                (item.count / articleVoteVO.totalVoteRecordCount) * 100
              )
            }}%
          </p>
          <div
            class="backgroundColor"
            :class="{
              haveBackgroundColor: item.isChoice && articleVoteVO.isAlreadyVote,
              noBackgroundColor: !item.isChoice && articleVoteVO.isAlreadyVote
            }"
            :style="{
              width: `${
                (item.count / articleVoteVO.totalVoteRecordCount) * 100
              }%`
            }"
          ></div>
        </li>
      </ul>
      <div
        v-if="articleVoteVO.voteOptions.length > 2 && !isVoteExpand"
        class="expand"
        @click="examineDetails"
      >
        {{ `查看全部${articleVoteVO.voteOptions.length}个选项>` }}
      </div>
      <div class="bottom">
        <div
          v-if="!isVoteEnd"
          class="button"
          :class="{ forbidden: articleVoteVO.isAlreadyVote }"
          @click="handleAddVote"
        >
          {{ articleVoteVO.isAlreadyVote ? '已投' : '投票' }}
        </div>
        <p>
          <span v-if="isVoteEnd">已结束</span>
          <span v-if="articleVoteVO.maxOptionalCount > 1">{{
              `最多${articleVoteVO.maxOptionalCount}项`
            }}</span>
          <span v-if="articleVoteVO.maxOptionalCount > 1" class="long">|</span>
          <span>{{ articleVoteVO.voteUserCount }}人已参与</span>
          <span class="long" v-if="!isVoteEnd">|</span
          ><span v-if="!isVoteEnd"
        >还有{{ getEndDate(articleVoteVO.endTime) }}天结束</span
        >
        </p>
      </div>
    </div>
    <div class="attList" v-if="attList && attList.length > 0">
      <van-tag
        v-for="(item, index) in attList"
        :key="index"
        size="medium"
        color="#FAF2F2"
        round
        @click.stop="handleAttClick(item.attId)"
      >
        @{{ item.attName }}
      </van-tag>
    </div>
    <!-- todo   一张图片-->
    <div
      v-if="mainImgUrl && !showImgList"
      class="oneImg"
      style="position: relative"
      :class="!isDetail && 'contentView_contentOverviewImg'"
      @click.stop="handleImgClick(imgList, 0)"
    >
      <img
        style="pointer-events: none"
        :src="mainImgUrlOnj.url"
        class="contentView_contentImg"

      />
      <div class="long-graph" v-if="mainImgUrlOnj.type === '长图'">
        <span>长图</span>
      </div>
    </div>
    <!-- todo   视频-->
    <video
      :src="videoAddr"
      controls
      @play="handlePlay"
      @pause="handlePause"
      @click.stop
      :poster="videoAddr + '?x-oss-process=video/snapshot,t_50,f_jpg,ar_auto'"
      v-if="videoAddr"
    />
    <!-- todo   多张图片-->
    <ImgList
      v-if="showImgList && imgList && imgList.length > 0"
      :isList="isList"
      :imageObjList="imageObjList"
      :imgList="imgList"></ImgList>
    <!-- todo   投票-->
    <vote-content
      v-if="showVote"
      :voteList="voteList"
      :pattern="pattern"
      :voteCount="voteCount"
      :title="title"
      :isMeVoted="isMeVoted"
      :voteStartTime="voteStartTime"
      :voteEndTime="voteEndTime"
      @click="handleCheckVote"
      @vote="handlePublicVote"
    />
    <!-- todo  圈子-->
    <div
      style="margin-top: 8px"
      :class="
        (locationTips.squareDeletedFlag != 1 &&
          JSON.stringify(locationTips.squareDeletedFlag) != '{}') ||
        (locationTips.circleDeletedFlag != 1 &&
          JSON.stringify(locationTips.circleDeletedFlag) != '{}')
          ? 'post-state-addition'
          : 'post-state'
      "
      v-show="
        locationTips.isShowCircleTips ||
        locationTips.isShowDeletedTips ||
        locationTips.isShowSquareTips
      "
    >
      <div
        :class="
          (locationTips.squareDeletedFlag != 1 &&
            JSON.stringify(locationTips.squareDeletedFlag) != '{}') ||
          (locationTips.circleDeletedFlag != 1 &&
            JSON.stringify(locationTips.circleDeletedFlag) != '{}')
            ? 'post-state-long'
            : 'post-state-left'
        "
      >
        <!-- todo 圈子-->
        <div
          class="flex-row-h-center post-state-circle"
          v-show="locationTips.isShowCircleTips && !circleShows"
          @click.stop="jumpCircle(locationTips.circleId)"
        >
          <span>{{ locationTips.circleTitle }} ></span>
        </div>
        <div
          :class="
            (locationTips.squareDeletedFlag != 1 &&
              JSON.stringify(locationTips.squareDeletedFlag) != '{}') ||
            (locationTips.circleDeletedFlag != 1 &&
              JSON.stringify(locationTips.circleDeletedFlag) != '{}')
              ? 'post-state-change'
              : ''
          "
          class="post-state-message"
          v-show="
            locationTips.isShowSquareTips || locationTips.isShowDeletedTips
          "
        >
          <div v-if="locationTips.isShowSquareTips">已同步至广场</div>
          <div v-show="locationTips.isShowDeletedTips">
            {{ deletePersonnel }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import voteContent from './voteContent'
import {ImagePreview} from 'vant'
import {addVote} from '@/api/article'
import {handleContent} from '@/utils/utils'
import {getCircleDetail} from '@/api/circle'
import {getOssImgUrlOneTenth} from '@/utils/utils'
import ImgList from './imgList'
// import { getCircleDetail } from "@/api/circle";
/**
 * 帖子详情
 */
export default {
  components: {
    voteContent,
    ImgList
  },
  props: {
    isTop: Boolean,
    hideTitle: Boolean,
    title: String,
    articleLabels: Array,
    content: String,
    attList: Array,
    mainImgUrl: String,
    imgList: {
      type: Array,
      default: () => []
    },
    videoAddr: String,
    showImgList: Boolean,
    isDetail: Boolean,
    showVote: Boolean,
    voteList: Array,
    pattern: Number,
    voteCount: Number,
    isMeVoted: Boolean,
    voteStartTime: String,
    voteEndTime: String,
    contentIndent: Boolean,
    isAddVote: Boolean,
    isList: Boolean,
    articleId: String,
    isVoteExpand: Boolean,
    topicList: {
      type: Array,
      default () {
        return []
      }
    },
    articleVoteVO: {
      type: Object,
      default () {
        return {}
      }
    },
    locationTips: {
      type: Object,
      default () {
        return {}
      }
    },
    circleShows: Boolean
  },
  watch: {
    imgList: {
      handler () {
        this.initData()
      },
      immediate: true,
      deep: true
    },
    mainImgUrl: {
      handler () {
        this.initData()
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      showImgPreview: false,
      startPosition: 0,
      isRadio: this.articleVoteVO.type === 1,
      activeVoteList: [],
      isVoteEnd: false,
      imageObjList: [],
      mainImgUrlOnj: {
        url: '',
        type: ''
      }
    }
  },
  // created () {
  //   this.initData()
  // },
  mounted () {
    if (this.$refs.content) {
      let a = this.$refs.content.querySelectorAll('a')
      for (let i = 0; i < a.length; i++) {
        a[i].addEventListener('click', function (e) {
          e.stopPropagation()
        })
      }
    }
  },
  computed: {
    deletePersonnel () {
      const circleDeletedFlag = this.locationTips.circleDeletedFlag + ''
      const squareDeletedFlag = this.locationTips.squareDeletedFlag + ''
      if (circleDeletedFlag === '2' && squareDeletedFlag === '2') {
        return '帖子已被圈主和系统管理员删除'
      }

      if (circleDeletedFlag === '3' && squareDeletedFlag === '2') {
        return '帖子已被圈管理员和系统管理员删除'
      }

      if (circleDeletedFlag === '1' && squareDeletedFlag === '2') {
        return '帖子已被系统管理员删除'
      }

      if (circleDeletedFlag === '2' && squareDeletedFlag === '1') {
        return '帖子已被圈主删除'
      }

      if (circleDeletedFlag === '3' && squareDeletedFlag === '1') {
        return '帖子已被圈管理员删除'
      }
      return ''
    }
  },
  methods: {
    // 播放视频时，其他视频暂停播放
    handlePlay (event) {
      const timeStamp = new Date().getTime() + ''
      const timeStampAttr = document.createAttribute('timeStamp')
      timeStampAttr.value = timeStamp
      event.target.setAttributeNode(timeStampAttr)
      const videoNodes = document.getElementsByTagName('video') || []
      videoNodes.forEach((item) => {
        if (item.getAttribute('timeStamp') !== timeStamp) {
          if (!item.paused) {
            item.pause()
          }
        }
      })
    },
    // 播放暂停时，其他视频暂停播放
    handlePause () {
      const videoNodes = document.getElementsByTagName('video') || []
      videoNodes.forEach((item) => {
        if (!item.paused) {
          item.pause()
        }
      })
    },
    // 页面初始化
    initData () {
      this.mainImgUrlOnj = {
        url: '',
        type: ''
      }
      this.imageObjList = []
      if (this.imgList.length > 0) {
        this.getImageType()
      }
      if (this.mainImgUrl) {
        this.checkPicUrlOne(this.mainImgUrl)
      }
    },
    // 处理文本
    handleContent,
    // 获取图片缩略图
    getOssImgUrlOneTenth,
    addVote (item) {
      if (this.articleVoteVO.isAlreadyVote || this.isVoteEnd) {
        return
      }
      if (this.isRadio) {
        this.activeVoteList = [item.id]
      } else {
        const index = this.activeVoteList.findIndex((i) => {
          return i == item.id
        })

        if (index != -1) {
          this.activeVoteList.splice(index, 1)
        } else {
          if (
            this.activeVoteList.length === this.articleVoteVO.maxOptionalCount
          ) {
            return this.$notify({type: 'warning', message: '超出最大可选项'})
          }
          this.activeVoteList.push(item.id)
        }
      }
    },
    handleAddVote () {
      if (this.articleVoteVO.isAlreadyVote) {
        return
      }
      if (this.activeVoteList.length === 0) {
        return this.$notify({type: 'warning', message: '请选择投票选项'})
      }
      if (this.isRadio || (this.activeVoteList.length >= 2 && !this.isRadio)) {
        addVote({
          articleId: this.articleId,
          voteOptionIds: this.activeVoteList
        }).then((res) => {
          let articleId = this.articleId
          if (res) {
            this.$emit('handleNewActive', {...res, articleId})
            return this.$notify({type: 'success', message: '投票成功'})
          }
        })
      } else {
        return this.$notify({type: 'warning', message: '至少选择两个选项'})
      }
    },
    getEndDate (time) {
      time = time.replace(/-/g, '/')
      let day = new Date(time).getTime() - new Date()
      if (day > 1) {
        return Math.ceil(day / 1000 / 60 / 60 / 24)
      } else {
        this.isVoteEnd = true
      }
    },
    handleImgClick (list, index) {
      ImagePreview({
        images: list,
        maxZoom: 12,
        startPosition: index
      })
    },
    handleCheckVote (item) {
      this.$emit('click', item)
    },
    handlePublicVote () {
      this.$emit('vote')
    },
    handleTagClick (tag) {
      this.$router.push({name: 'searchTag', query: {tag: tag}})
    },
    handleAttClick (id) {
      this.$emit('attClick', id)
    },
    examineDetails () {
      this.$router.push({name: 'topicDetail', query: {id: this.articleId}})
    },
    // 跳转圈子
    jumpCircle (id) {
      this.getCircleDetail(id)
    },
    /**
     * todo 根据id获取圈子详情
     * @returns {Promise<void>}
     */
    async getCircleDetail (id) {
      await getCircleDetail(id)
      await this.$router.push({
        path: '/app/circle/circleXq',
        query: {
          id: id + '',
          refreshCircle: 'true'
        }
      })
    },
    /**
     * 获取图片的属性
     */
    checkPicUrlOne (url) {
      const that = this
      let img = new Image()
      // img.src = url + '?x-oss-process=image/resize,l_400'
      img.src = url + '?x-oss-process=image/resize,p_10'
      img.onerror = function () {
        that.checkPicUrlOneFail(url)
        return false
      }
      if (img.complete) {
        that.addImgAttOne(url, img)
      } else {
        img.onload = function () {
          that.addImgAttOne(url, img)
          img.onload = null //避免重复加载
        }
      }
    },
    /**
     * 获取oss图片的属性失败
     */
    checkPicUrlOneFail (url) {
      const that = this
      let img = new Image()
      img.src = url
      if (img.complete) {
        that.addImgAttOne(url, img, 'fail')
      } else {
        img.onload = function () {
          that.addImgAttOne(url, img, 'fail')
          img.onload = null //避免重复加载
        }
      }
    },
    /**
     * 将图片的属性push到数组中
     */
    async addImgAttOne (url, img, type = 'normal') {
      if (img.height / img.width >= 1.5 && img.height > 100) {
        if (type === 'fail') {
          this.mainImgUrlOnj['url'] = url
        } else {
          this.mainImgUrlOnj['url'] = `${url}?x-oss-process=image/crop,w_${
            img.width * 10
          },h_${img.width * 10}`
        }
        this.mainImgUrlOnj['type'] = '长图'
      } else {
        if (type === 'fail') {
          this.mainImgUrlOnj['url'] = url
        } else {
          this.mainImgUrlOnj['url'] = `${url}?x-oss-process=image/resize,l_400`
        }
        this.mainImgUrlOnj['type'] = ''
      }
    },
    /**
     * 获取图片的宽高
     */
    async getImageType () {
      this.imgList.forEach((url, index) => {
        let obj = {
          url: url
        }
        this.imageObjList.push(obj)
        this.checkPicUrl(url, index)
      })
    },
    /**
     * 获取图片的属性
     */
    checkPicUrl (url, index) {
      const that = this
      let img = new Image()
      img.src = this.getOssImgUrlOneTenth(url)
      img.onerror = function () {
        this.checkPicUrlFail(url, index)
        return false
      }
      if (img.complete) {
        that.addImgAtt(url, img, index)
      } else {
        img.onload = function () {
          that.addImgAtt(url, img, index)
          img.onload = null //避免重复加载
        }
      }
    },
    /**
     * 获取图片的属性
     */
    checkPicUrlFail (url, index) {
      const that = this
      let img = new Image()
      img.src = url
      if (img.complete) {
        that.addImgAtt(url, img, index, 'fail')
      } else {
        img.onload = function () {
          that.addImgAtt(url, img, index, 'fail')
          img.onload = null //避免重复加载
        }
      }
    },
    /**
     * 将图片的属性push到数组中
     */
    addImgAtt (url, img, index, type = 'normal') {
      this.imageObjList[index]['width'] = img.width
      this.imageObjList[index]['height'] = img.height
      let typeKey = null
      let typeValue = null
      if (img.height / img.width >= 1.5 && img.height > 100) {
        typeKey = '2'
        typeValue = '长图'
        if (type === 'fail') {
          this.imageObjList[index]['url'] = url
        } else {
          this.imageObjList[index]['url'] = `${url}?x-oss-process=image/crop,w_${
            img.width * 10
          },h_${img.width * 10}`
        }
      } else {
        if (type === 'fail') {
          this.imageObjList[index]['url'] = url
        } else {
          this.imageObjList[index][
            'url'
            ] = `${url}?x-oss-process=image/resize,l_400`
        }
      }
      const typeList = url.split('.')
      if (typeList[typeList.length - 1] === 'gif') {
        typeKey = '3'
        typeValue = '动图'
      }
      this.imageObjList[index]['typeKey'] = typeKey
      this.imageObjList[index]['typeValue'] = typeValue
      this.imageObjList = JSON.parse(JSON.stringify(this.imageObjList))
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .content {
    .label {
      color: #fe5800 !important;
      margin: 0 2px;
      font-size: 14px;
    }
  }
}

.oneImg {
  position: relative;

  .contentView_contentImg {
    width: 100%;
  }

  .long-graph {
    padding: 4px 6px;
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
    border-radius: 3px 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.contentView {
  position: relative;

}

.title-container {
  display: flex;
  align-items: center;
}

.vote-container {
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 14px;
  padding: 15px 12px 13px 12px !important;
  width: 100%;
  background: #f6f6f6;
  border-radius: 6px;

  .active {
    border: 1px solid rgba(254, 88, 0, 0.25) !important;
    color: #fe5800 !important;
  }

  h3 {
    font-size: 16px;
    color: #000000;
    line-height: 18px;
  }

  .vote-head {
    display: flex;
    align-items: center;

    .type {
      padding-top: 1px;
      text-align: center;
      margin-left: 10px;
      width: 30px;
      height: 16px;
      background: rgba(254, 88, 0, 0.1);
      border-radius: 1px;
      font-size: 10px;
      color: #fe5800;
      line-height: 16px;
    }
  }

  .vote-info {
    //margin-top: 8px;

    span {
      font-size: 12px;
      color: #6d7278;
    }

    .long {
      margin: 0 5px;
    }
  }

  .underline {
    margin-top: 11px;
    margin-bottom: 11px;
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
  }

  .vote-option {
    .isAlreadyVote {
      border: 1px solid rgba(254, 88, 0, 0.25) !important;
      color: #fe5800 !important;
    }

    .noAlreadyVote {
      color: #000000;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 11px;
      width: 100%;
      height: 40px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 18px;
      color: #5475a6;
      z-index: 1;

      .content {
        max-width: 237px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .backgroundColor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 5px 0px 0px 5px;
        z-index: -1;
      }

      .haveBackgroundColor {
        background: rgba(254, 88, 0, 0.1) !important;
      }

      .noBackgroundColor {
        background: #f1f1f1 !important;
      }

      img {
        margin-left: 10px;
        margin-right: 10px;
        width: 12px;
        height: 12px;
      }

      .percentage {
        margin-left: auto;
        font-size: 14px;
      }
    }
  }

  .expand {
    font-size: 12px;
    color: #6d7278;
    line-height: 18px;
    margin-bottom: 13px;
  }

  .bottom {
    display: flex;
    align-items: center;

    .forbidden {
      background: #cccccc !important;
    }

    .button {
      width: 64px;
      height: 32px;
      background: #fe5800;
      border-radius: 2em;
      color: #ffffff;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      margin-right: 10px;
    }

    p {
      font-size: 12px;
      color: #6d7278;
      line-height: 18px;
    }

    .long {
      margin: 0 5px;
    }
  }
}

.contentView_head {
  flex-wrap: wrap;
}

.topicList {
  margin-top: 5px;

  flex: 100%;
  display: flex;
  flex-wrap: wrap;

  .topic {
    margin-right: 5px;
    //height: 18px;
    color: $mainColor;
    font-size: 13px;
    word-break: break-all;
  }
}

.preViewImage {
  z-index: 100000 !important;
}

.contentView {
  video {
    height: 200px;
    width: 100%;
  }

  &_head {
    display: flex;
    align-items: center;

    .topTag {
      background-color: $mainColor;
      font-size: 12px;
      color: white;
      width: 28px;
      min-width: 28px;
      height: 17px;
      border-radius: 2px;
      margin: 0 7px 0 0;
      text-align: center;
      padding: 0 2px;
    }

    .title {
      @include title;
      @include ellipsis(1);
      font-weight: bold;
      margin: 0;
    }

    .deleteLineClamp {
      -webkit-line-clamp: 3;
    }
  }

  &_contentText {
    @include mainText;
    position: relative;
    text-align: left;
    margin-top: 9px;
    margin-bottom: 10px;
    word-wrap: break-word;
    white-space: pre-wrap;

    .tagTitle {
      margin-right: 4px;
      overflow: hidden;
      font-size: 14px;
      color: $mainColor;
      /*font-weight: bold;*/
    }

    .content_indent {
      p {
        text-indent: 1em;
      }
    }

    .detailText {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .attList {
    margin-bottom: 5px;

    .van-tag {
      color: $mainColor;
      font-size: 10px;
      margin-right: 3px;
    }
  }

  .ellipsis {
    @include ellipsis();
  }

  .twoPicture {
    padding-right: 50px;
  }

  &_contentOverviewImg {
    display: flex;
    flex-direction: column;
    //flex-direction: row;
    //align-items: center;
    border-radius: 6px;
    max-height: 300px;
    overflow: hidden;
  }

  .van-icon::before {
    position: absolute;
    left: 330px;
    top: 20px;
  }

  .post-state {
    //padding-top: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
  }

  .post-state-addition {
    padding-top: 10px;
    background-color: #fff;
    font-size: 10px;
  }

  .post-state-circle {
    max-width: 200px;
    word-break: break-all;
    color: #fa6400;
    background: #f6f6f6;
    border-radius: 100px;
    padding: 6px 10px;
    margin-right: 10px;
  }

  .post-state-message {
    // margin-left: 10px;
    color: #999999;
  }

  .post-state-left {
    display: flex;
    align-items: center;
  }

  .post-state-change {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .post-state-long {
    // display: flex;
    // // align-items: center;
    // flex-direction: column;
  }

  .post-state-right {
    color: #999999;
  }
}
</style>
