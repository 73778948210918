<template>
  <div
    class="contentView_imgList"
    :class="imgList.length === 4 ? 'twoPicture' : ''"
  >
    <div
      class="contentView_imgBox"
      :class="{threeMultiple: (index + 1) % 3 === 0 && imgList.length !== 4,
      isUserCenter: $route.name === 'userInfoDetail'}"
      v-for="(item, index) in isList
          ? imageObjList.slice(0, 6)
          : imageObjList"
      :key="index"
    >
      <img
        @click.stop="handleImgClick(imgList, index)"
        class="contentView_contentImg"
        :src="item.url"
      />
      <div class="long-graph" v-if="item.typeKey">
        <span>{{ item.typeValue }}</span>
      </div>
      <div
        v-if="imageObjList.length > 6 && index === 5 && isList"
        class="flex-center-row exceed"
      >
        <span>+{{ imageObjList.length - 6 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {ImagePreview} from "vant";

export default {
  name: "imgList",
  props: {
    isList: {
      type: Boolean,
      default: false
    },
    imgList: {
      type: Array,
      default: () => []
    },
    imageObjList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleImgClick (list, index) {
      ImagePreview({
        images: list,
        maxZoom: 12,
        startPosition: index
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.contentView_imgList {
  display: flex;
  flex-wrap: wrap;

  .contentView_imgBox {
    position: relative;
    width: 105px;
    height: 105px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    .long-graph {
      padding: 4px 6px;
      color: #ffffff;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 12px;
      border-radius: 3px 0;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .contentView_contentImg {
      width: 100%;
    }
  }

  .isUserCenter{
    width: 100px;
    height: 100px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .threeMultiple {
    margin-right: 0;
  }

  .exceed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #FFFFFF;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
